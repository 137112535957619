import React, { useEffect, useState } from 'react'
import Navbar from '../components/Header/Navbar'
import Home from '../components/Home/Home'
import About from '../components/About/About'
import RecentWork from '../components/RecentWork/RecentWork'
import Services from '../components/Services/Services'
import FAQs from '../components/FAQs/FAQs'
import Companies from '../components/Companies/Companies'
import Benefits from '../components/Benefits/Benefits'
import Footer from '../components/Footer/Footer'
import Testimonial from '../components/Testimonial/Testimonial'
import { PuffLoader } from 'react-spinners';

const Portfolio = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.addEventListener('load', () => {
            setLoading(false);
        });

        return () => window.removeEventListener('load', () => { });
    }, []);

    return (
        <>
            {loading ? (
                <div className="loader">
                    <PuffLoader
                        color="#000000"
                        loading={loading}
                        size={50}
                    />
                </div>
            ) : (
                <div>
                        <Navbar />
                        <Home />
                        <Companies />
                        <About />
                        <Benefits />
                        <RecentWork />
                        <Services />
                        <Testimonial />
                        <FAQs />
                        <Footer />
                </div>
            )}

        </>
    )
}

export default Portfolio