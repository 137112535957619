import React, { useEffect } from 'react'
import AOS from 'aos'
import Slider from "react-slick";
import './style.css'

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
};

const data = [
    {
        id: 1,
        feedback: "Amazing work! OM knocked it out of the park with top-notch designs in just a few hours after we kicked things off. Super impressed and totally recommend them for quick and quality work.",
        name: "Jonathan Billington",
        position: "CEO Founder"
    },
    {
        id: 2,
        feedback: "OM's skills are outstanding will 100% be using him in the future",
        name: "Abhishek Tayshete",
        position: "Developer"
    },
    {
        id: 3,
        feedback: "Om finished the jobs exactly as instructed. His design style was simple but appealing. His Figma work (layers, assets, icons, typography) was tidy and seamless. Definitely going to rehire him for other UX projects.",
        name: "Muhammad Ikhsan Ramadhani",
        position: "Developer"
    },
    {
        id: 4,
        feedback: "Great at what he does would 100% recommend using his services.",
        name: "Vijay Rathode",
        position: "Developer"
    },
]

const Testimonial = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <div className='padding-top bg-cream1 testimonial' id='testimonial'>
            <div className="container-xl">
                <div className="mx-auto main-content">
                    <h6 className='fm red highlight-para text-center' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>Testimonial</h6>
                    <h1 className='fs-1 dark text-center gfm main-heading' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>Customer Feedback</h1>
                    <p className='fm dark highlight-para text-center mt-md-3 mt-2' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>Hear What Our Customers Have to Say!</p>
                </div>

                <div className="mt-md-5 mt-4">
                    <Slider
                        {...settings}
                        className="Testimonial__slider"
                    >
                        {data.map((item) => (
                            <div className='position-relative review mx-auto d-block pb-4' key={item.id}>
                                <div className="review__box d-flex justify-content-center p-sm-4 p-3">
                                    <div className="review__box__content text-center py-2">
                                        <p className='highlight-para fm dark text-center pb-3'>“{item.feedback}”</p>

                                        <h6 className='gfm highlight-para dark mb-0'>{item.name}</h6>
                                        <p className='red review__box__small fw-semibold mb-0'>{item.position}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>


            </div>
        </div>
    )
}

export default Testimonial