import React, { useEffect } from 'react'
import AOS from 'aos'
import './style.css'
import icon1 from '../../assets/images/benefits/Where-Ideas-Take-Flight.svg'
import icon2 from '../../assets/images/benefits/Speedster-Deliveries.svg'
import icon3 from '../../assets/images/benefits/Fixed-Per-Project-Rate.svg'
import icon4 from '../../assets/images/benefits/Top-Notch-Quality-Every-Time.svg'
import icon5 from '../../assets/images/benefits/Flexibility-That-Scalability.svg'
import icon6 from '../../assets/images/benefits/Unique-and-all-yours.svg'

const data = [
    {
        id: 1,
        title: "Where Ideas Take Flight",
        desc: "Where ideas take flight and become breathtaking designs.",
        icon: icon1
    },
    {
        id: 2,
        title: "Speedster Deliveries",
        desc: "Get your design one at a time in just a few days on average.",
        icon: icon2
    },
    {
        id: 3,
        title: "Fixed Per project rate",
        desc: "No Surprises here! Pay the same fixed price each per project.",
        icon: icon3
    },
    {
        id: 4,
        title: "Top-Notch Quality Every Time",
        desc: "Insane design quality at your fingertips whenever you need it.",
        icon: icon4
    },
    {
        id: 5,
        title: "Flexibility That Scalability",
        desc: "Scale up or down as needed, and pause or cancel at anytime.",
        icon: icon5
    },
    {
        id: 6,
        title: "Unique and all yours",
        desc: "Each of your designs is made especially for you and is 100% your.",
        icon: icon6
    },
]

const Benefits = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <div className='benefits padding-top'>
            <div className="container-xl">
                <div className="mx-auto main-content mb-5">
                    <h6 className='fm red highlight-para text-center' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>Benefits</h6>
                    <h1 className='fs-1 dark text-center gfm main-heading' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>Transforming Lives, One Benefit at a Time</h1>
                    <p className='fm dark highlight-para text-center mt-md-3 mt-2' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>Your Gateway to Personal Growth and Success Awaits</p>
                </div>

                <div className="row g-lg-5 g-sm-4 g-3">
                    {data.map((item) => (
                        <div className="col-lg-4 col-sm-6" key={item.id} data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>
                            <div className='benefits__content mx-auto d-flex flex-column align-items-center text-center'>
                                <img src={item.icon} alt="" className='benefits__icon animate-star' />
                                <h6 className="font20 gfm mb-sm-2 mb-1">
                                    {item.title}
                                </h6>
                                <p className='fm dark fs-6'>{item.desc}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Benefits