import React, { useState, useEffect } from 'react'
import AOS from 'aos'
import './style.css'
import project1 from '../../assets/images/work/project1.png'
import project2 from '../../assets/images/work/project2.png'
import project3 from '../../assets/images/work/project3.png'
import project4 from '../../assets/images/work/project4.png'
import project5 from '../../assets/images/work/project5.png'
import project6 from '../../assets/images/work/project6.png'
import project7 from '../../assets/images/work/project7.png'
import project8 from '../../assets/images/work/project8.png'
import project9 from '../../assets/images/work/project9.png'
import { Image } from 'antd'
import WorkBackground from './WorkBackground'
import { Link } from "react-scroll";

const data = [
    {
        id: 1,
        image: project1,
    },
    {
        id: 2,
        image: project2,
    },
    {
        id: 3,
        image: project3,
    },
    {
        id: 4,
        image: project4,
    },
    {
        id: 5,
        image: project5,
    },
    {
        id: 6,
        image: project6,
    },
    {
        id: 7,
        image: project7,
    },
    {
        id: 8,
        image: project8,
    },
    {
        id: 9,
        image: project9,
    },
]

const RecentWork = () => {
    const [more, setMore] = useState(false)
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <div className='padding-top bg-skyblue' id='work'>
            <WorkBackground>
                <div className="mx-auto main-content d-flex flex-column align-items-center" id='recentWork'>
                    <h6 className='fm red highlight-para text-center' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>Recent Work</h6>
                    <h1 className='fs-1 dark text-center gfm main-heading' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>Explore My Recent Creations</h1>
                    {!more && <Link
                        smooth
                        spy
                        to={"moreWork"}
                        delay={0}
                        duration={200}>
                        <button className='text-capitalize mt-md-4 mt-2 mb-2 fm white Navbar__btn d-flex rounded-pill fs-6'
                            data-aos={"fade-right"}
                            data-aos-duration={1200}
                            data-aos-offset={80}
                            onClick={() => setMore(!more)}>
                            <span className='Navbar__btn__text'>View All Work</span>
                        </button>
                    </Link>}
                </div>

                <div className="row g-md-4 g-sm-3 g-2 pt-4">
                    {data.slice(0, 6).map((item) => (
                        <div className="col-md-4 col-6" key={item.id} data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>
                            <div className='rounded work__box'>
                                <Image src={item.image} />
                            </div>
                        </div>
                    ))}
                </div>

                <div className="row g-md-4 g-sm-3 g-2 pt-md-4 pt-sm-3 pt-2" id='moreWork'>
                    {more &&
                        data.slice(6).map((item) => (
                            <div className="col-md-4 col-6" key={item.id} data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>
                                <div className='rounded work__box'>
                                    <Image src={item.image} />
                                </div>
                            </div>
                        ))
                    }
                </div>

                {more && <div className='d-flex justify-content-center' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>
                    <Link
                        smooth
                        spy
                        to={"recentWork"}
                        delay={0}
                        duration={200}>
                        <button className='text-capitalize mt-5 fm white Navbar__btn  d-flex rounded-pill fs-6'
                            onClick={() => setMore(!more)}>
                            <span className='Navbar__btn__text'>View Less Work</span>
                        </button>
                    </Link>
                </div>}
            </WorkBackground>
        </div>
    )
}

export default RecentWork