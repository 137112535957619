import React, { useEffect } from 'react'
import AOS from 'aos'
import { Collapse } from 'antd';
import './style.css'
import arrow from "../../assets/images/icons/arrow-head.svg"

const items = [
    {
        key: '1',
        label: 'What is your experience in creating marketing collateral?',
        children: <p className='fm dark collapse__para mb-0'>I have extensive experience collaborating with cross-functional teams to create various marketing materials, including brochures, flyers, and social media graphics. I am well-versed in understanding the unique requirements of each project and tailoring designs to meet specific marketing goals.</p>,
    },
    {
        key: '2',
        label: `Can you provide examples of the websites you've designed and developed?`,
        children: <p className='fm dark collapse__para mb-0'>Certainly! You can find examples of my website layouts, wireframes, and user interfaces in my portfolio. These designs showcase my ability to create visually appealing and user-friendly digital experiences for clients across different industries.</p>,
    },
    {
        key: '3',
        label: 'How do you approach design to ensure visual appeal?',
        children: <p className='fm dark collapse__para mb-0'>I utilize a combination of typography, color theory, and layout principles to create designs that are not only aesthetically pleasing but also effectively communicate the intended message. I pay careful attention to details to ensure a cohesive and visually attractive final product.</p>,
    },
    {
        key: '4',
        label: 'How do you manage multiple projects simultaneously?',
        children: <p className='fm dark collapse__para mb-0'>Managing multiple projects is a key aspect of my skill set. I am accustomed to working in fast-paced environments and excel at prioritizing tasks to meet tight deadlines. Effective communication and project management tools play a crucial role in ensuring the smooth progression of all projects under my supervision.</p>,
    },
    {
        key: '5',
        label: 'How do you ensure brand consistency across designs and materials?',
        children: <p className='fm dark collapse__para mb-0'>Maintaining brand consistency is a top priority in my design process. I adhere to brand guidelines and standards, ensuring that all marketing collateral aligns with the brand's identity. This includes consistent use of colors, fonts, and overall design elements to create a unified and recognizable brand image.</p>,
    },
]

const FAQs = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <div className='padding-top bg-cream3' id='faqs'>
            <div className="container-xl">
                <div className="mx-auto main-content mb-md-5 mb-4">
                    <h6 className='fm red highlight-para text-center' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>FAQs</h6>
                    <h1 className='fs-1 dark text-center gfm main-heading' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>Frequently Asked Questions</h1>
                </div>

                <Collapse expandIcon={({ isActive }) =>
                    <img
                        src={arrow}
                        alt="collapse-arrow"
                        style={{
                            transform: isActive ? 'rotate(90deg)' : 'rotate(0deg)',
                            transition: 'transform 0.2s ease-in-out',
                        }}
                    />
                } expandIconPosition={"end"} bordered={false} items={items} defaultActiveKey={['1']} data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80} />

            </div>
        </div>
    )
}

export default FAQs