import React, { useEffect } from 'react'
import AOS from 'aos'
import image from '../../assets/images/Om-Vaghela.png'

const About = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <div className='bg-skyblue padding-top' id='about' >
            <div className="container-xl">
                <div className="mx-auto main-content">
                    <h6 className='fm red highlight-para text-center' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>About Me</h6>
                    <h1 className='fs-1 dark text-center gfm main-heading' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>UI/UX Design Professional with Graphic Design Expertise</h1>
                    <p className='fm dark highlight-para text-center mt-md-3 mt-2' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>UI/UX Design Professional with Graphic Design Expertise | Shaping User-Centric Interfaces and Captivating Graphics.</p>
                </div>

                <div className="row pt-lg-5 pt-3 g-xxl-5 g-lg-4 g-2">
                    <div className="col-lg-6">
                        <h1 className='gfm fs-1 main-heading dark' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>Hi👋🏻, I am Om</h1>
                        <p className='fm dark highlight-para' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>I am writing to express my interest in the <span className='fw-semibold'>Graphic Designer and UX/UI Designer Expert</span>. My name is Om and I have 2+ years of experience in UX/UI Design and Graphic Design. </p>
                        <p className='fm dark highlight-para' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>While I do not possess the UX/UI Design or Graphic Design certification, I am highly passionate about the field and completed a course in these areas in just 5 months. During this time, I worked diligently to acquire new skills and knowledge, and I am confident that my abilities will make me a valuable asset to your team. </p>
                        <p className='fm dark highlight-para' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>As a creative problem solver with strong attention to detail, I am proficient in Adobe Creative Suite and have experience in various design projects. My skills and eagerness to learn and improve make me an ideal candidate for the position.</p>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center align-items-start" data-aos={"fade-left"} data-aos-duration={1200} data-aos-offset={80}>
                        <img src={image} alt="Om-Vaghela" className='img-fluid rounded-4 mx-auto' />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default About