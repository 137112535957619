import { Drawer } from 'antd'
import React from 'react'
import { Link } from "react-scroll";

const Offcanvas = ({ onClose, open, data }) => {
    return (
        <Drawer
            onClose={onClose}
            open={open}
            placement="left"
            className="nav_drawer p-0 w-100"
        >
            <div>
                <ul className='list-unstyled fm d-flex flex-column gap-4'>
                    {data.map((item) => (
                        <li className='dark fs-6 navbar__item w-100 d-block' key={item.id}><Link
                            activeClass="active"
                            smooth
                            spy
                            to={item.ref}
                            delay={0}
                            duration={500}
                            onClick={onClose}
                            className="Navbar__item__link w-100 d-block pointer dark fm fs-6 text-decoration-none fw-semibold transition text-capitalize"
                        >
                            {item.text}
                        </Link></li>
                    ))}
                </ul>

                <button className='text-capitalize fm white mt-5 Navbar__btn d-flex rounded-pill fs-6 w-100'><span className='Navbar__btn__text'>Schedule a call</span></button>
            </div>
        </Drawer>
    )
}

export default Offcanvas