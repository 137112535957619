import React from 'react'
import './style.css'
import { Link } from "react-scroll";
import black from '../../assets/images/objects/home-black.svg'
import red from '../../assets/images/objects/home-red.svg'
import yellow from '../../assets/images/objects/sponge.svg'
import topStar from '../../assets/images/objects/home-star3.svg'
import leftStar from '../../assets/images/objects/star1.svg'
import bottomStar from '../../assets/images/objects/home-star2.svg'
import slide2 from '../../assets/images/slider/slide2.jpg'
import slide3 from '../../assets/images/slider/slide3.jpg'
import slide4 from '../../assets/images/slider/slide4.jpg'
import slide5 from '../../assets/images/slider/slide5.jpg'
import slide6 from '../../assets/images/slider/slide6.jpg'
import slide7 from '../../assets/images/slider/slide7.jpg'
import slide8 from '../../assets/images/slider/slide8.jpg'
import slide9 from '../../assets/images/slider/slide9.jpg'
import slide10 from '../../assets/images/slider/slide10.jpg'
import slide11 from '../../assets/images/slider/slide11.jpg'
import slide12 from '../../assets/images/slider/slide12.jpg'
import animation from '../../assets/images/circle-animation.gif'
import Slider from "react-slick";
import { Image } from 'antd';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    fade: true,
};

const data = [
    {
        id: 2,
        image: slide2,
    },
    {
        id: 3,
        image: slide3,
    },
    {
        id: 4,
        image: slide4,
    },
    {
        id: 5,
        image: slide5,
    },
    {
        id: 6,
        image: slide6,
    },
    {
        id: 7,
        image: slide7,
    },
    {
        id: 8,
        image: slide8,
    },
    {
        id: 9,
        image: slide9,
    },
    {
        id: 10,
        image: slide10,
    },
    {
        id: 11,
        image: slide11,
    },
    {
        id: 12,
        image: slide12,
    },
]

const Home = () => {
    return (
        <div className='padding-top bg-cream1' id='home'>
            <div className="pt-5 pb-sm-5">
                <div className="container-xl">
                    <div className="row g-lg-4 g-5 pt-lg-5 pt-4">
                        <div className="col-lg-6 d-flex align-items-center position-relative">
                            <img src={leftStar} alt="" className='home-right__leftStar animate-star' />
                            <div className="home__content position-relative">
                                <img src={bottomStar} alt="" className='home-right__bottomStar animate-star' />
                                <h1 className='dark gfm'>Empowering <span className='nowrap'><span className='position-relative'>Ideas<img className='red-circle' alt='' src={animation}></img></span>💡</span>Through Design Excellence</h1>
                                <p className='dark fm mb-4 mt-3 pb-lg-2'>Figma Expert & UI/UX Designer Extraordinaire</p>
                                <Link
                                    smooth
                                    spy
                                    to={"work"}
                                    delay={0}
                                    duration={500}>
                                    <button className='text-capitalize fm white Navbar__btn mx-lg-0 mx-auto rounded-pill fs-6'><span className='Navbar__btn__text'>Explore My Work</span></button></Link>
                            </div>
                        </div>

                        <div className="col-lg-6 d-flex justify-content-lg-end justify-content-center">
                            <div className="home__right position-relative">
                                <img src={black} alt="" className='home-right__black animate-object d-md-block d-none' />
                                <img src={red} alt="" className='home-right__red animate-star' />
                                <img src={yellow} alt="" className='home-right__yellow  animate-star' />
                                <img src={topStar} alt="" className='home-right__topStar animate-star' />



                                <Slider {...settings}>
                                    {data.map((item) => (
                                        <Image
                                            key={item.id}
                                            src={item.image}
                                            alt=""
                                            className='rounded-4 home__image img-fluid '
                                            placeholder={
                                                <Image
                                                    preview={false}
                                                    src={`${item.image}?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200`}
                                                />
                                            } />
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home