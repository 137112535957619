import React, { useEffect, useState } from 'react'
import logo from '../../assets/images/logo.svg'
import './style.css'
import { Link } from "react-scroll";
import AOS from "aos";
import { CgMenuRight } from "react-icons/cg";
import Offcanvas from './Offcanvas';

const data = [
    {
        id: 1,
        text: "Home",
        ref: "home",
    },
    {
        id: 2,
        text: "About me",
        ref: "about",
    },
    {
        id: 3,
        text: "Recent work",
        ref: "work",
    },
    {
        id: 4,
        text: "Services",
        ref: "services",
    },
    {
        id: 5,
        text: "Testimonial",
        ref: "testimonial",
    },
    {
        id: 6,
        text: "FAQs",
        ref: "faqs",
    },
];

const Navbar = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    const [scroll, setScroll] = useState(false);
    const [open, setOpen] = useState(false);

    const onClose = () => {
        setOpen(false);
    };

    const changeBackground = () => {
        if (window.scrollY >= 66) {
            setScroll(true);
        } else {
            setScroll(false);
        }
    };

    useEffect(() => {
        changeBackground();
        window.addEventListener("scroll", changeBackground);
    });
    return (
        <>
            <header className='Navbar bg-cream1'
                id="navbar"
                data-aos={"fade-down"}
                data-aos-duration={1000}
                data-aos-offset={0}
                style={{
                    boxShadow: scroll ? "0px 2px 15px #3333334d" : "0px 0px 0px #3333334d",
                }}>
                <div className="container-xl d-flex justify-content-between gap-3 align-items-center h-100">
                    <a href="/"><img src={logo} alt="" className='Navbar__logo' /></a>

                    <ul className='list-unstyled fm mb-0 d-lg-flex d-none gap-xl-5 gap-4'>
                        {data.map((item) => (
                            <li className='dark fs-6 navbar__item' key={item.id}><Link
                                activeClass="active"
                                smooth
                                spy
                                to={item.ref}
                                delay={0}
                                duration={500}
                                className="Navbar__item__link pointer dark fm fs-6 text-decoration-none fw-semibold transition text-capitalize"
                            >
                                {item.text}
                            </Link></li>
                        ))}
                    </ul>

                    <a target='_blank' rel="noreferrer" href="https://api.whatsapp.com/message/DXOVIYWEA4HTH1?autoload=1&app_absent=0">
                        <button className='text-capitalize fm white Navbar__btn d-lg-flex d-none rounded-pill fs-6'><span className='Navbar__btn__text'>Schedule a call</span></button>
                    </a>
                    <button className='bg-transparent p-0 border-0 d-lg-none' onClick={() => setOpen(!open)}><CgMenuRight className='burger-icon dark' /></button>
                </div>
            </header>
            <Offcanvas onClose={onClose} open={open} data={data} /></>
    )
}

export default Navbar