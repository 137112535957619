import React from 'react'
import Slider from "react-slick";
import "./style.css";
import company1 from '../../assets/images/company/company1.svg'
import company2 from '../../assets/images/company/company2.svg'
import company3 from '../../assets/images/company/company3.svg'
import company4 from '../../assets/images/company/company4.svg'
import company5 from '../../assets/images/company/company5.svg'
import company6 from '../../assets/images/company/company6.svg'

const settings = {
    dots: false,
    infinite: true,
    speed: 5000, 
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 0,
    cssEase: 'linear', 
    responsive: [
        {
            breakpoint: 992,
            settings: {
                arrows: false,
                slidesToShow: 4,
                slidesToScroll: 4,
                autoplay: true,
                variableWidth: false,
                speed: 5000,
                autoplaySpeed: 0,
            },
        },
        {
            breakpoint: 768,
            settings: {
                arrows: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                autoplay: true,
                variableWidth: false,
                speed: 5000,
                autoplaySpeed: 0,
            },
        },
        {
            breakpoint: 576,
            settings: {
                arrows: false,
                slidesToShow: 2,
                slidesToScroll: 2,
                autoplay: true,
                variableWidth: false,
                speed: 5000,
                autoplaySpeed: 0,
            },
        },
    ],
};


const data = [
    {
        id: 1,
        src: company1
    },
    {
        id: 2,
        src: company2
    },
    {
        id: 3,
        src: company3
    },
    {
        id: 4,
        src: company4
    },
    {
        id: 5,
        src: company5
    },
    {
        id: 6,
        src: company6
    },
]

const Companies = () => {
    return (
        <div className='py-4 bg-dark company'>
            <div className="container-xl py-sm-2">
                <Slider
                    {...settings}
                    className="Testimonial__slider"
                >
                    {data.map((item) => (
                        <div key={item.id} className={`company__box${item.id} company__box d-inline-flex align-items-center py-1`}><img src={item.src} alt="" className={`company--img${item.id}`} /></div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export default Companies