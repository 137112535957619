import React from 'react'
import object from "../../assets/images/objects/services-black.svg";
import star1 from "../../assets/images/objects/star1.svg";
import sponge from "../../assets/images/objects/sponge.svg";

const Background = ({ children }) => {
    return (
        <div className="position-relative">
            <img
                src={object}
                alt=""
                className="services__box__object d-lg-block d-none"
            />
            <img
                src={star1}
                alt=""
                className="services__box__star1 animate-star d-lg-block d-none"
            />
            <img
                src={star1}
                alt=""
                className="services__box__star-bottom animate-star d-lg-block d-none"
            />
            <img
                src={star1}
                alt=""
                className="services__box__star-top animate-star d-lg-block d-none"
            />
            <img
                src={star1}
                alt=""
                className="services__box__star-right animate-star d-lg-block d-none"
            />
            <img
                src={sponge}
                alt=""
                className="services__box__sponge animate-star d-lg-block d-none"
            />

            {children}
        </div>
    );
}

export default Background