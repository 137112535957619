import React, { useEffect } from 'react'
import './style.css'
import Background from './Background'
import AOS from 'aos'


const data = ["User Flow", "Landing Pages", "Mockups", "Ux Research", "Typography",
    "interaction Design", "Android Design", "IOS Design", "Wireframing", "Software Design",
    "Saas Design", "Brand identity", "Digital Art", "Digital Painting", "Web layout", "App Design",
    "Prototyping", "Logo Design", "Web App Design", "User Experience (UX) Design", "User Interface (UI) Design",
    "Vector Illustration", "Design Research", "Website Redesign", "Poster Design", "Social Post Design", "Digital illustration", "Design System", "Motion Design", "Visual Design"
]

const Services = () => {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <div className='padding-top services' id='services'>
            <div className="container-xl">
                <div className='mx-auto main-content'>
                    <h6 className='fm red highlight-para text-center' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>Services</h6>
                    <h1 className='fs-1 dark text-center gfm main-heading' data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>Apps, websites, logos & more.</h1>
                </div>

                <Background>
                    <div className="services__box mt-sm-4 mt-3 mb-0">
                        <div className="row g-md-5 g-sm-4 g-3">
                            <div className="col-lg-4 col-sm-6" data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>
                                <ul className='list-unstyled mb-0 fm dark d-flex flex-column gap-3 fw-semibold'>
                                    {data.slice(0, 10).map((item) => (
                                        <li className='services__box__item' key={item}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-lg-4 col-sm-6" data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>
                                <ul className='list-unstyled mb-0 fm dark d-flex flex-column gap-3 fw-semibold'>
                                    {data.slice(10, 20).map((item) => (
                                        <li className='services__box__item' key={item}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-lg-4 col-sm-6 d-lg-block d-none" data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>
                                <ul className='list-unstyled mb-0 fm dark d-flex flex-column gap-3 fw-semibold'>
                                    {data.slice(20).map((item) => (
                                        <li className='services__box__item' key={item}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-sm-6 d-lg-none mt-3" data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>
                                <ul className='list-unstyled mb-0 fm dark d-flex flex-column gap-3 fw-semibold'>
                                    {data.slice(20, 25).map((item) => (
                                        <li className='services__box__item' key={item}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-sm-6 d-lg-none mt-3" data-aos={"fade-right"} data-aos-duration={1200} data-aos-offset={80}>
                                <ul className='list-unstyled mb-0 fm dark d-flex flex-column gap-3 fw-semibold'>
                                    {data.slice(25, 30).map((item) => (
                                        <li className='services__box__item' key={item}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                    </div>
                </Background>
            </div>
        </div>
    )
}

export default Services