import React from 'react'
import yellow from '../../assets/images/objects/sponge.svg'
import red from '../../assets/images/objects/sponge-red.svg'
import black from '../../assets/images/objects/work-black.svg'

const WorkBackground = ({ children }) => {
    return (
        <div className="container-xl position-relative">
            <img src={yellow} alt="" className='workobj--yellow position-absolute animate-star d-lg-block d-none' />
            <img src={red} alt="" className='workobj--red position-absolute animate-star d-lg-block d-none' />
            <img src={black} alt="" className='workobj--black position-absolute animate-star d-lg-block d-none' />
            {children}
        </div>
    )
}

export default WorkBackground